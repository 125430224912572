var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: !_vm.parkChannelId ? "添加通道" : "编辑通道",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "500px",
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Channel_name"),
                    prop: "parkChannelName",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: { maxlength: "50", placeholder: "请输入通道名称" },
                    model: {
                      value: _vm.form.parkChannelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parkChannelName", $$v)
                      },
                      expression: "form.parkChannelName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "通道类型",
                    prop: "channelType",
                    required: "",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "formWidth",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.channelType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "channelType", $$v)
                        },
                        expression: "form.channelType",
                      },
                    },
                    _vm._l(_vm.channelTypeList, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.desc, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.channelType == 4
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否直连场外",
                        prop: "directConnect",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "formWidth",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.directConnect,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "directConnect", $$v)
                            },
                            expression: "form.directConnect",
                          },
                        },
                        [
                          _c("el-option", { attrs: { label: "是", value: 1 } }),
                          _c("el-option", { attrs: { label: "否", value: 0 } }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属主控机",
                    prop: "lightBoxId",
                    required: "",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "formWidth",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.lightBoxId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "lightBoxId", $$v)
                        },
                        expression: "form.lightBoxId",
                      },
                    },
                    _vm._l(_vm.lightBoxList, function (item) {
                      return _c("el-option", {
                        key: item.lightBoxId,
                        attrs: { label: item.name, value: item.lightBoxId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              [3, 4].includes(_vm.form.channelType)
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属子车场",
                        prop: "parkId",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "formWidth",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.parkId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "parkId", $$v)
                            },
                            expression: "form.parkId",
                          },
                        },
                        _vm._l(_vm.parkList, function (item) {
                          return _c("el-option", {
                            key: item.parkId,
                            attrs: { label: item.parkName, value: item.parkId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("返回"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: { click: _vm.save },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }