var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.equipmentId ? "编辑设备" : "添加设备",
            visible: _vm.dialogVisible,
            width: "703px",
            "custom-class": "dialog",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.formInline,
                "label-width": "80px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Equipment_number"),
                    prop: "equipmentCode",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "dialog-width",
                    attrs: {
                      maxlength: 14,
                      placeholder: "请输入设备编号，支持字母+数字，限长14位",
                    },
                    model: {
                      value: _vm.formInline.equipmentCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "equipmentCode", $$v)
                      },
                      expression: "formInline.equipmentCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Equipment_name"),
                    prop: "equipmentName",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "dialog-width",
                    attrs: {
                      maxlength: 14,
                      placeholder:
                        "请输入设备名称，支持汉字+字母+数字，限长14位",
                    },
                    model: {
                      value: _vm.formInline.equipmentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "equipmentName", $$v)
                      },
                      expression: "formInline.equipmentName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联泊位", prop: "equipmentBerth" } },
                [
                  _c("el-transfer", {
                    ref: "transfer",
                    attrs: {
                      "target-order": "push",
                      titles: ["未选择", "已选择"],
                      filterable: "",
                      "filter-placeholder": "请输入内容",
                      props: { label: "berthCode", key: "berthId" },
                      data: _vm.berthData,
                    },
                    model: {
                      value: _vm.formInline.equipmentBerth,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "equipmentBerth", $$v)
                      },
                      expression: "formInline.equipmentBerth",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }