var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看设备",
            visible: _vm.dialogVisible,
            width: "500",
            "custom-class": "dialog-detail",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "90px" } },
            [
              _c("el-form-item", { attrs: { label: "设备编号：" } }, [
                _c(
                  "span",
                  { staticClass: "of", attrs: { title: _vm.equipmentCode } },
                  [_vm._v(" " + _vm._s(_vm.equipmentCode))]
                ),
              ]),
              _c("el-form-item", { attrs: { label: "设备名称：" } }, [
                _c(
                  "span",
                  { staticClass: "of", attrs: { title: _vm.equipmentName } },
                  [_vm._v(" " + _vm._s(_vm.equipmentName))]
                ),
              ]),
              _c("el-form-item", { attrs: { label: "关联泊位：" } }, [
                _c("div", { staticClass: "listStyle" }, [
                  _vm._v(" " + _vm._s(_vm.hasSelectedBerth.join()) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }