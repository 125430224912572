var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.berthId ? "编辑泊位" : "添加泊位",
            visible: _vm.dialogVisible,
            width: "510px",
            "close-on-click-modal": false,
            "custom-class": "dialog",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "berthform",
              attrs: {
                rules: _vm.rules,
                model: _vm.formInline,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Berth_number"),
                    prop: "berthCode",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "dialog-width",
                    attrs: {
                      maxlength: 14,
                      disabled: !!_vm.berthId,
                      placeholder: "请输入泊位编码，支持字母+数字，限长14位",
                    },
                    model: {
                      value: _vm.formInline.berthCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "berthCode", $$v)
                      },
                      expression: "formInline.berthCode",
                    },
                  }),
                ],
                1
              ),
              !_vm.berthId
                ? _c(
                    "el-form-item",
                    { attrs: { label: "批量添加", prop: "batchAddNum" } },
                    [
                      _c("el-input", {
                        staticClass: "dialog-width",
                        attrs: {
                          maxlength: 3,
                          placeholder:
                            "请输入泊位数量，请不要超过车场的总泊位数",
                        },
                        model: {
                          value: _vm.formInline.batchAddNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "batchAddNum", _vm._n($$v))
                          },
                          expression: "formInline.batchAddNum",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("searchModule.Service_label") } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.serviceAttr,
                        callback: function ($$v) {
                          _vm.serviceAttr = $$v
                        },
                        expression: "serviceAttr",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "3" } }, [
                        _vm._v("充电泊位"),
                      ]),
                      _c("el-checkbox", { attrs: { label: "4" } }, [
                        _vm._v("残疾人泊位"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }