var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "div",
          { staticClass: "searchWrapper" },
          [
            _vm._m(0),
            _c("el-input", {
              attrs: {
                placeholder: "输入关键字进行过滤",
                "suffix-icon": "el-icon-search",
              },
              model: {
                value: _vm.filterText,
                callback: function ($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText",
              },
            }),
            _c("el-tree", {
              ref: "tree",
              staticClass: "filter-tree",
              attrs: {
                data: _vm.navData,
                props: _vm.defaultProps,
                "default-expand-all": "",
                "node-key": "id",
                "expand-on-click-node": false,
                "check-on-click-node": "",
                "filter-node-method": _vm.filterNode,
              },
              on: { "node-click": _vm.handleNodeClick },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var node = ref.node
                    var data = ref.data
                    return _c("div", { staticClass: "custom-tree-node" }, [
                      _c(
                        "div",
                        {
                          staticClass: "nav-node",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.getEquipmentDetail(data)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "nav-item" }, [
                            _vm._v(_vm._s(node.label)),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "warning-tip",
                              class: data.offlineTime > 5 ? "red" : "green",
                            },
                            [
                              !data.state
                                ? _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/warning-tip.png"),
                                    },
                                  })
                                : _vm._e(),
                              data.offlineTime
                                ? _c("span", [
                                    _vm._v(_vm._s(data.offlineTime) + "min"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm.showFlag
          ? _c(
              "div",
              { staticClass: "tableWrapper" },
              [
                _c(
                  "div",
                  { staticClass: "table-btns" },
                  [
                    _vm.authority.button.addzkj
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.add.open()
                              },
                            },
                          },
                          [_vm._v("添加主控机")]
                        )
                      : _vm._e(),
                    _vm.authority.button.addtd
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.access.open()
                              },
                            },
                          },
                          [_vm._v("添加通道")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    attrs: {
                      "header-cell-class-name": "header-call-style",
                      data: _vm.lightBoxList,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: _vm.$t("list.index"),
                        width: "70",
                        align: "center",
                      },
                    }),
                    _vm._l(_vm.tableCols, function (item) {
                      return _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          prop: item.prop,
                          label: item.label,
                          width: item.width,
                          formatter: item.formatter,
                          align: "center",
                        },
                      })
                    }),
                    _c("el-table-column", {
                      attrs: { label: "通讯状态", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", { staticClass: "contact-status" }, [
                                  _c("i", {
                                    class: row.state == 1 ? "green" : "red",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(row.state == 1 ? "在线" : "离线") +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3331532341
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", align: "center", width: "80" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-dropdown",
                                  {
                                    on: {
                                      command: function ($event) {
                                        return _vm.handleCommand(
                                          $event,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "0" },
                                        attrs: { type: "text", size: "small" },
                                      },
                                      [
                                        _vm._v("操作"),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-down",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _vm.authority.button.editzkj
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "a" } },
                                              [_vm._v("编辑 ")]
                                            )
                                          : _vm._e(),
                                        _vm.authority.button.deletezkj
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "b" } },
                                              [_vm._v("删除 ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3896753173
                      ),
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "table-btns" },
                  [
                    _vm.authority.button.syncthird
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.parkStatus == 1,
                              type: "primary",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sync(1)
                              },
                            },
                          },
                          [_vm._v("三方同步")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    attrs: {
                      "header-cell-class-name": "header-call-style",
                      data: _vm.channelList,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: _vm.$t("list.index"),
                        width: "70",
                        align: "center",
                      },
                    }),
                    _vm._l(_vm.tableColsAccess, function (item) {
                      return _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          prop: item.prop,
                          label: item.label,
                          width: item.width,
                          formatter: item.formatter,
                          align: "center",
                        },
                      })
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("searchModule.Equipment_status"),
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", { staticClass: "contact-status" }, [
                                  _c("i", {
                                    class: row.state == 1 ? "green" : "red",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.state === 1
                                          ? "在线"
                                          : row.state === 0
                                          ? "离线"
                                          : "待同步"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1324994687
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", align: "center", width: "80" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-dropdown",
                                  {
                                    on: {
                                      command: function ($event) {
                                        return _vm.handleCommand(
                                          $event,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "0" },
                                        attrs: { type: "text", size: "small" },
                                      },
                                      [
                                        _vm._v("操作"),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-down",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _vm.authority.button.edittd
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "c" } },
                                              [_vm._v("编辑")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "e" } },
                                          [_vm._v("设备管理")]
                                        ),
                                        _vm.authority.button.deletetd
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "d" } },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3256986901
                      ),
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "table-btns" },
                  [
                    _vm.authority.button.syncdevice
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.parkStatus == 1,
                              type: "primary",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sync(2)
                              },
                            },
                          },
                          [_vm._v("设备同步")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("add", {
                  ref: "add",
                  attrs: { parkId: _vm.details.parkId },
                  on: { searchData: _vm.searchData },
                }),
                _c("access", {
                  ref: "access",
                  attrs: {
                    lightBoxList: _vm.lightBoxList,
                    channelTypeList: _vm.channelTypeList,
                  },
                  on: { searchData: _vm.searchData },
                }),
              ],
              1
            )
          : _c("manage", {
              ref: "equipmentManage",
              attrs: {
                equipmentDetail: _vm.equipmentDetail,
                channelTypeList: _vm.channelTypeList,
                msEquipmentType: _vm.msEquipmentType,
              },
              on: { searchData: _vm.searchData },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 导航图 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }