var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: !_vm.lightBoxId ? "添加主控机" : "编辑主控机",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "500px",
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "主控板编码", prop: "sn" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: {
                      disabled: _vm.eocState == 3,
                      maxlength: "20",
                      placeholder: "请输入主控板编码",
                    },
                    model: {
                      value: _vm.form.sn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sn", $$v)
                      },
                      expression: "form.sn",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "监控板编码", prop: "monitorBoardSn" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: {
                      disabled: _vm.eocState == 3,
                      maxlength: "20",
                      placeholder: "请输入监控板编码",
                    },
                    model: {
                      value: _vm.form.monitorBoardSn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "monitorBoardSn", $$v)
                      },
                      expression: "form.monitorBoardSn",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "主控机名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: { maxlength: "20", placeholder: "请输入主控机名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "brandId", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "formWidth",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.brandId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "brandId", $$v)
                        },
                        expression: "form.brandId",
                      },
                    },
                    _vm._l(_vm.brandGroup, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.desc, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("返回"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: { click: _vm.save },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }